import { TextField } from '@mui/material'
import React from 'react'

function CustomInput({ placeholder, value, setValue,inputProps,error}) {
    return (
        <div style={{ display: 'inline', marginInline: 30 }}>
            <TextField id="outlined-basic"
                label={placeholder}
                variant="outlined"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                // type=
                inputProps={inputProps}
                error={error}
            />
        </div>
    )
}

export default CustomInput
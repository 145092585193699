import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Pagination, Table } from "antd";
import styles from '../../css/Dashboard.module.css'
import { API, base_url, requestGet, requestPost } from "../../util/request";
import { useNavigate } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart'
import { Bar } from 'react-chartjs-2';
import logo from '../../assets/images/logo.png'
import OpenLogo from '../../assets/images/LinesOpenSquare.png'
import BookedLogo from '../../assets/images/Booked Square.png'
import PickedUpLogo from '../../assets/images/PickedUp Square.png'
import LoadedLogo from '../../assets/images/Loaded Square.png'
import ShippedLogo from '../../assets/images/Shipped Square.png'
import DeliveredLogo from '../../assets/images/Delivered Square.png'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import HomeCountCard from "../../components/HomeCountCard";
import MiniBarChart from "../../components/MiniBarChart";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import moment from "moment";
import { colors } from "../../util/colors";
import TableNote from "../../components/TableNote";
import Cartons from "../../components/Cartons";
import IFrame from "../../components/IFrame";
import PODetails from "../../components/PODetails";
import Filter from "../../components/Filter";
import AscendDescend from "../../components/AscendDescend";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const navigate = useNavigate()
  const currentDate = moment().format('M/DD/YYYY')
  const startOfThisMonth = moment().startOf('month').format('M/DD/YYYY');
  const endOfThisMonth = moment().endOf('month').format('M/DD/YYYY');
  const startOfPreviousMonth = moment().subtract(1, "month").startOf('month').format('M/DD/YYYY');
  const endOfPreviousMonth = moment().subtract(1, 'months').endOf('month').format('M/DD/YYYY');

  const [thisMonthCartons, setThisMonthCartons] = useState([-1])
  const [thisMonthTotalCartons, setThisMonthTotalCartons] = useState(0)
  const [lastMonthCartons, setLastMonthCartons] = useState([-1])
  const [lastMonthTotalCartons, setLastMonthTotalCartons] = useState(0)
  const [tableData, setTableData] = useState([])
  const [bookedCartons, setBookedCartons] = useState('')
  const [pickedCartons, setPickedCartons] = useState('')
  const [loadedCartons, setLoadedCartons] = useState('')
  const [shippedCartons, setShippedCartons] = useState('')
  const [deliveredCartons, setDeliveredCartons] = useState('')
  const [openCartons, setOpenCartons] = useState('')
  const [tableLoading, SetTableLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [selectedRow, setSelectedRow] = useState({})
  const [endPointForCartons, setEndPointForCartons] = useState(API.CARTONS)
  const [selectedCartonStatus, setSelectedCartonStatus] = useState('Order Lines')
  const [open, setOpen] = useState(false);
  const [iFrameModal, setIFrameModal] = useState(false)
  const [totalCountsRefresher, setRotalCountsRefresher] = useState(false)
  const [PODetailsModal, setPODetailsModal] = useState(false)
  const [filterModal, setFilterModal] = useState(false)
  const [PODetailEndpoint, setPODetailEndpoint] = useState('')
  const [ascend, setAscend] = useState({})
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log('--------------------->', ascend)
  useEffect(() => {
    document.title = 'KWITbox'
    getTableData()
  }, [currentPage, search, selectedCartonStatus, ascend])
  useEffect(() => {
    getMiniGraphData()
  }, [])
  useEffect(() => {
    setSearch('')
    setCurrentPage(1)
    setAscend({})
  }, [selectedCartonStatus])
  useEffect(() => {
    getTotalCounts()
  }, [totalCountsRefresher])
  const onRowClick = (record, rowIndex) => {
    return {
      onClick: async (ev) => {
        console.log(record, rowIndex);
        if (selectedCartonStatus != 'Order Lines') {
          setSelectedRow(record)
          setEndPointForCartons(`${API.CARTONS}?[q][PONumber_eq]=${record.PONumber}&[q][LNSKUNumber_eq]=${record.LNSKUNumber}`)
          setOpen(true)
        }
      },
    };
  }
  const CartonlabelRenderer = (label, value) => {
    return <AscendDescend label={label} setAscend={(data) => { setAscend(data) }} ascend={ascend} tableType={'Cartons'} value={value} />
  }
  const PODetailslabelRenderer = (label, value) => {
    return <AscendDescend label={label} setAscend={(data) => { setAscend(data) }} ascend={ascend} tableType={'PO_line'} value={value} />
  }
  const onPODetailClick = (data) => {
    setPODetailEndpoint(`${API.PO_Line_Detail}?[q][PONumber_eq]=${data.PONumber}&[q][LNSKUNumber_eq]=${data.LNSKUNumber}`)
    setPODetailsModal(true)
  }
  const PODetailRender = (data) => {
    console.log('po number ', data)
    return (
      <p onClick={() => onPODetailClick(data)} style={{ cursor: 'pointer', color: colors.blue, paddingInline: 5, marginInlineStart: 7 }}>+</p>
    )
  }//onPODetailClick
  const cartonColumns = [
    {
      // title: () => <AscendDescend label={'Shippable Unit'} setAscend={(data) => { setAscend(data) }} ascend={ascend} tableType={'Cartons'} value={"CartonID"}/>,
      title: CartonlabelRenderer('Shippable Unit', 'CartonID'),
      width: 120,
      dataIndex: "CartonID",
      key: "-2",
      fixed: "left",
      onCell: onRowClick,
    },
    {
      title: CartonlabelRenderer('PO', 'PONumber'),
      width: 90,
      dataIndex: "PONumber",
      key: "0",
      fixed: "left",
      onCell: onRowClick,

    },
    {
      title: CartonlabelRenderer('SKU', 'LNSKUNumber'),
      dataIndex: "LNSKUNumber",
      key: "1",
      width: 90,
      onCell: onRowClick,
    },
    {
      title: CartonlabelRenderer('Booking Number', 'BookingNumber'),
      dataIndex: "BookingNumber",
      key: "2",
      onCell: onRowClick,
      width: 120
    },
    {
      title: CartonlabelRenderer('Status', 'CartonIDStatus'),
      dataIndex: "CartonIDStatus",
      key: "-1",
      onCell: onRowClick,
      width: 100
    },
    {
      title: CartonlabelRenderer('Booked', 'BookingTimeStamp'),
      dataIndex: "BookingTimeStamp",
      key: "3",
      onCell: onRowClick,
      width: 150
    },
    {
      title: CartonlabelRenderer('Picked Up', 'PickUpTimeStamp'),
      dataIndex: "PickUpTimeStamp",
      key: "4",
      onCell: onRowClick,
      width: 150,

    },
    {
      title: CartonlabelRenderer('Loaded', 'LoadTimeStamp'),
      dataIndex: "LoadTimeStamp",
      key: "5",
      onCell: onRowClick,
      width: 150
    },
    // {
    //   title: "Load Number",
    //   dataIndex: "LoadNumber",
    //   key: "10",
    //   width: 100

    // },
    {
      title: CartonlabelRenderer('Container Number', 'ContainerNumber'),
      dataIndex: "ContainerNumber",
      key: "9",
      onCell: onRowClick,
      width: 100
    },
    {
      title: CartonlabelRenderer('Seal Number', 'SealNumber'),
      dataIndex: "SealNumber",
      key: "8",
      onCell: onRowClick,
      width: 100

    },
    {
      title: 'Shipped',
      dataIndex: "ShippedTimeStamp",
      key: "6",
      onCell: onRowClick,
      width: 150
    },
    {
      title: CartonlabelRenderer('Delivered', 'DeliveredTimeStamp'),
      dataIndex: "DeliveredTimeStamp",
      key: "7",
      onCell: onRowClick,
      width: 150
    },
    {
      title: "Details",
      width: 100,
      // dataIndex: "PONumber",
      key: "-1",
      fixed: "right",
      render: PODetailRender,
    },
    {
      title: "Notes",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (data) => {
        return (
          <TableNote data={data} endPoint={`${API.CARTONS}/${data.id}.json?[carton][Note]=`} />
        )
      }
    },
  ];
  const columns = [
    {
      // title: "PO",
      title: PODetailslabelRenderer('PO', 'PONumber'),
      width: 90,
      dataIndex: "PONumber",
      key: "0",
      fixed: "right",
      onCell: onRowClick,
    },
    {
      title: PODetailslabelRenderer('SKU', 'LNSKUNumber'),
      width: 90,
      dataIndex: "LNSKUNumber",
      key: "0",
      fixed: "left",
      onCell: onRowClick,
    },
    {
      title: PODetailslabelRenderer('Qty', 'LNQuantityOrdered'),
      dataIndex: "LNQuantityOrdered",
      key: "1",
      onCell: onRowClick,
      width: 70
    },

    // {
    //   title: "Qty Remaining",
    //   dataIndex: "LNQuantityRemaining",
    //   key: "2",
    //   onCell: onRowClick,
    //   width: 100
    // },
    // {
    //   title: "Status",
    //   dataIndex: "Status",
    //   key: "3",
    //   onCell: onRowClick,
    //   width: 80
    // },
    {
      title: 'Ship By',
      dataIndex: "ShipbyDate",
      key: "4",
      onCell: onRowClick,
      width: 100
    },
    {
      title: "Cancel By",
      dataIndex: "CancelDate",
      key: "5",
      onCell: onRowClick,
      width: 100
    },
    {
      title: PODetailslabelRenderer('SKU Desc', 'LNSKUDescription'),
      dataIndex: "LNSKUDescription",
      key: "6",
      onCell: onRowClick,
      width: 150
    },
    {
      title: PODetailslabelRenderer('Style Desc', 'LNStyleDescription'),
      dataIndex: "LNStyleDescription",
      key: "7",
      onCell: onRowClick,
      width: 150
    },
    {
      title: PODetailslabelRenderer('Color Desc', 'LNColorDescription'),
      dataIndex: "LNColorDescription",
      key: "8",
      onCell: onRowClick,
      width: 150
    },
    {
      title: "Details",
      title: PODetailslabelRenderer('Details', 'PONumber'),
      width: 100,
      // dataIndex: "PONumber",
      key: "0",
      fixed: "right",
      render: PODetailRender,
    },
    {
      title: "Notes",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (data) => {
        return (
          <TableNote data={data} endPoint={`${API.PO_LINES}/${data.id}.json?[po_line][Note]=`} />
        )
      }
    },

  ];
  const getTotalCounts = () => {

    //?[q][AuditDate_gteq]=${date}&[q][AuditDate_lteq]=${date}
    requestGet(`${API.BOOKED_COUNT}`,)
      .then((response) => {
        console.log('--------->BOOKED_COUNT', response)
        setBookedCartons(response)
      }).catch(error => console.log('error in BOOKED_COUNT', error))

    requestGet(`${API.PICKED_UP_COUNT}`,)
      .then((response) => {
        console.log('--------->PICKED_UP_COUNT', response)
        setPickedCartons(response)
      }).catch(error => console.log('error in PICKED_UP_COUNT', error))

    requestGet(`${API.LOADINGS_COUNT}`,)
      .then((response) => {
        console.log('--------->LOADINGS_COUNT', response)
        setLoadedCartons(response)
      }).catch(error => console.log('error in LOADINGS_COUNT', error))

    requestGet(`${API.SHIPPED_COUNT}`,)
      .then((response) => {
        console.log('--------->SHIPPED_COUNT', response)
        setShippedCartons(response)
      }).catch(error => console.log('error in SHIPPED_COUNT', error))
    requestGet(`${API.DELIVERED_COUNT}`,)
      .then((response) => {
        console.log('--------->DeliveredCartons', response)
        setDeliveredCartons(response)
      }).catch(error => console.log('error in DeliveredCartons', error))
    requestGet(`${API.PO_LINES_COUNT}?[q][Status_null]=${true}`,)
      .then((response) => {
        console.log('--------->PO_LINES_COUNT', response)
        setOpenCartons(response)
      }).catch(error => console.log('error in DeliveredCartons', error))

  }
  const getMiniGraphData = () => {
    //?[q][AuditDate_gteq]=${date}&[q][AuditDate_lteq]=${date}
    requestGet(`${API.COUNT_BY_DAY}?[q][AuditDate_gteq]=${startOfThisMonth}&[q][AuditDate_lteq]=${endOfThisMonth}`,)
      .then((response) => {
        console.log('--------->COUNT_BY_DAY', response)
        let total = 0
        response.forEach(element => {
          total += element.total
        });
        setThisMonthTotalCartons(total)
        setThisMonthCartons(response)
      }).catch(error => console.log('error in COUNT_BY_DAY', error))
    requestGet(`${API.COUNT_BY_DAY}?[q][AuditDate_gteq]=${startOfPreviousMonth}&[q][AuditDate_lteq]=${endOfPreviousMonth}`,)
      .then((response) => {
        console.log('--------->COUNT_BY_DAY', response)
        let total = 0
        response.forEach(element => {
          total += element.total
        });
        setLastMonthTotalCartons(total)
        setLastMonthCartons(response)
      }).catch(error => console.log('error in COUNT_BY_DAY', error))
  }
  const getTableData = async () => {
    SetTableLoading(false)
    try {
      let url = selectedCartonStatus == 'Order Lines' ?
        `${API.PO_SEARCH}?page=${currentPage}&[q][PONumber_or_LNStyleDescription_or_LNSKUDescription_or_LNSKUNumber_or_LNColorDescription_or_LNQuantityOrdered_cont]=${search}` :
        `${API.CARTONS}?page=${currentPage}&[q][CartonIDStatus_eq]=${selectedCartonStatus}&[q][LNSKUNumber_or_CartonID_or_BookingNumber_or_PONumber_cont]=${search}`
      // type: label, value: 'asc',tableType 
      // {"q"=>{"s"=>"title desc"}}
      console.log('--------------->', ascend)
      if (ascend.columnName) {
        url += `&[q][s]=${ascend.columnName} ${ascend.value}`
      }
      const response = await requestGet(url)
      console.log('---------->', response)
      setTotal(response.pagination.count)
      if (response?.stock_statuses) {
        var tempArray = response?.stock_statuses.map(item => {
          return {
            ...item,
            CancelDate: item.POHeader.CancelDate,
            ShipbyDate: item.POHeader.ShipbyDate
          }
        })
      } else if (response?.cartons) {
        tempArray = response?.cartons ?? []
      }
      setTableData(tempArray)
    } catch (error) {
      console.log('errors in getTableData dashboard', error)
    }
    SetTableLoading(true)
  }


  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //     },
  //     title: {
  //       display: true,
  //       text: 'Chart.js Bar Chart',
  //     },
  //   },
  // };

  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


  // const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // };





  return (
    <div className="container-fluid" style={{ paddingTop: '10px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <div>
          <img src={logo} style={{ width: '200px', height: '80px' }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }}>
          <MiniBarChart title={'This Month'} text={`${thisMonthTotalCartons} cartons booked`} data={thisMonthCartons} barsColor={colors.primary} />
          <MiniBarChart title={'Last Month'} text={`${lastMonthTotalCartons} cartons booked`} data={lastMonthCartons} barsColor={colors.primary} />
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <div onClick={() => {
          setRotalCountsRefresher(!totalCountsRefresher)
          setOpenCartons('')
          setBookedCartons('')
          setPickedCartons('')
          setLoadedCartons('')
          setShippedCartons('')
          setDeliveredCartons('')
        }} style={{ position: 'absolute', right: 40, cursor: 'pointer', padding: 10 }}>
          <RotateLeftIcon style={{ color: colors.black }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: colors.white, marginTop: 10, padding: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ fontWeight: 'bold', fontSize: '17px', marginTop: '10px', marginBottom: 0 }}>Order</p>
            <HomeCountCard image={OpenLogo} type={`Order Lines`}
              count={openCartons[0]?.total ?? -1}
              onCardPress={() => {
                setSelectedCartonStatus('Order Lines')
              }} />
          </div>
          <div style={{ backgroundColor: colors.primary, width: 5 }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontWeight: 'bold', fontSize: '17px', marginTop: '10px', marginBottom: 0, marginInlineStart: 20 }}>Shippable Units Tracked</p>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <HomeCountCard image={BookedLogo} type={`Booked`}
                count={bookedCartons[0]?.total ?? -1}
                onCardPress={() => {
                  setSelectedCartonStatus('Booked')
                }} />
              <HomeCountCard image={PickedUpLogo} type={`Picked Up`}
                count={pickedCartons[0]?.total ?? -1}
                onCardPress={() => {
                  setSelectedCartonStatus('PickedUp')
                }} />
              <HomeCountCard image={LoadedLogo} type={`Loaded`}
                count={loadedCartons[0]?.total ?? -1}
                onCardPress={() => {
                  setSelectedCartonStatus('Loaded')
                }} />
              <HomeCountCard image={ShippedLogo} type={`Shipped`}
                count={shippedCartons[0]?.total ?? -1}
                onCardPress={() => {
                  setSelectedCartonStatus('Shipped')
                }} />
              <HomeCountCard image={DeliveredLogo} type={`Delivered`}
                count={deliveredCartons[0]?.total ?? -1}
                onCardPress={() => {
                  setSelectedCartonStatus('Delivered')
                }} />

              <div className={styles.UpdateStatusButton} onClick={() => setIFrameModal(!iFrameModal)}>
                <div style={{ backgroundColor: colors.primary, padding: 10, borderRadius: 10, fontSize: 20, color: colors.white, cursor: 'pointer' }}>Update Status</div>
              </div>
              <IFrame show={iFrameModal} setShow={() => setIFrameModal(false)} />
            </div>
          </div>
        </div>

      </div>
      <div className={styles.tableContainer}>
        <div className={styles.paginationContainer} style={{ marginBottom: '10px', }}>
          <div><p className={styles.selectedCartonStatusText} style={{ color: colors.primary }}>{selectedCartonStatus} :</p></div>
          <div>
            {/* <div onClick={() => setFilterModal(true)} style={{ marginRight: 10, display: 'inline', cursor: 'pointer', zIndex: 90 }}><FilterAltRoundedIcon style={{ color: colors.black }} /></div> */}
            <input type="text" name="first_name" required placeholder="Search" value={search} onChange={(text) => setSearch(text.target.value)} />
          </div>
        </div>
        <Table
          loading={!tableLoading}
          pagination={{ defaultPageSize: 10, position: ['none'], showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => { }, // click row
              onDoubleClick: (event) => { }, // double click row
              onContextMenu: (event) => { }, // right button click row
              onMouseEnter: (event) => { }, // mouse enter row
              onMouseLeave: (event) => { } // mouse leave row
            };
          }}
          onHeaderRow={(columns, index) => {
            return {
              onClick: () => { } // click header row
            };
          }}
          // className={tableCSS}
          columns={(selectedCartonStatus == 'Order Lines') ? columns : cartonColumns}
          dataSource={tableData}
          scroll={{
            x: 1000,
            // y: 300
          }}

        />
        <div className={styles.paginationContainer}>
          <div />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {selectedCartonStatus != 'Order Lines' && tableData.length != 0 &&
              <div className={styles.exportButton} onClick={() => { }} style={{ backgroundColor: colors.primary }}>
                <a href="http://3.135.30.168:3000/cartons.csv" download="customCarton.csv">
                  <p style={{ marginTop: 5, marginBottom: 5, color: colors.white, fontWeight: 'bold' }}>Export as CSV</p>
                </a>
              </div>}
            <Pagination showSizeChanger={false} defaultCurrent={1} total={total} onChange={(page) => setCurrentPage(page)} />
          </div>
        </div>

      </div>
      {/* <div style={{ width: '300px', height: '300px' }}>
        <PieChart
          label={({ dataEntry }) => dataEntry.title + dataEntry.value}
          labelStyle={{ fontSize: '8px' }}
          animate={true}
          animationDuration={1000}
          data={[
            { title: 'One', value: 10, color: 'green' },
            { title: 'Two', value: 15, color: 'yellow' },
            { title: 'Three', value: 20, color: 'orange' },
          ]}
        />
      </div> */}
      {/* <div style={{ width: '500px', height: '500px' }}>
        <Bar options={options} data={data} />;
      </div> */}
      {open && <Cartons data={selectedRow} open={open} closeModal={handleClose} cartonsEndPoint={endPointForCartons} PODetailRenderer={PODetailRender} />}
      {PODetailsModal && <PODetails open={open} closeModal={() => setPODetailsModal(false)} cartonsEndPoint={PODetailEndpoint} />}
      {filterModal && <Filter open={true} closeModal={() => setFilterModal(false)} cartonsEndPoint={endPointForCartons} />}

    </div>
  )
};

export default Dashboard;
const style = {
  position: "absolute",
  // top: '50%',
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  // bgcolor: "red",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
import { Pagination, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import styles from '../css/Cartons.module.css'
import { API, base_url, fetchrequestGet, requestGet, requestPut } from '../util/request';
import { colors } from '../util/colors';
import TableNote from './TableNote';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CSVLink } from "react-csv";
import AscendDescend from './AscendDescend';
function Cartons({ data, closeModal, cartonsEndPoint, PODetailRenderer }) {

    const [tableData, setTableData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState('')
    const [tableLoading, SetTableLoading] = useState(false)
    const [value, onChange] = useState(new Date());
    const [ascend, setAscend] = useState({})

    useEffect(() => {
        getTableData()
    }, [currentPage, search, ascend])
    const getTableData = async () => {
        SetTableLoading(false)

        // const message = await requestGet(`${API.CARTONS}?[q][PONumber_eq]=${data.PONumber}&[q][LNSKUNumber_eq]=${data.LNSKUNumber}`)
        if (search)
            var url = `${cartonsEndPoint}?[q][LNSKUNumber_cont]=${search}`
        else
            url = cartonsEndPoint
        if (ascend.columnName) {
            url += `&[q][s]=${ascend.columnName} ${ascend.value}`
        }
        var message = await requestGet(url)

        console.log('---------->', message)
        setTotal(message?.pagination?.count ?? 1)

        setTableData(message?.cartons ?? [])
        SetTableLoading(true)
    }
    const CartonlabelRenderer = (label, value) => {
        return <AscendDescend label={label} setAscend={(data) => { setAscend(data) }} ascend={ascend} tableType={'Cartons'} value={value} />
    }
    const modelCoumns = [
        {
            title: CartonlabelRenderer("Shippable Unit", 'CartonID'),
            width: 120,
            dataIndex: "CartonID",
            key: "-2",
            fixed: "left"
        },
        {
            title: CartonlabelRenderer("PO", 'PONumber'),
            width: 90,
            dataIndex: "PONumber",
            key: "0",
            fixed: "left",
            // render: PODetailRenderer
        },
        {
            title: CartonlabelRenderer("SKU", 'LNSKUNumber'),
            dataIndex: "LNSKUNumber",
            key: "1",
            width: 90,
            // render: PODetailRenderer
        },
        {
            title: CartonlabelRenderer("Booking Number", 'BookingNumber'),
            dataIndex: "BookingNumber",
            key: "2",
            width: 120
        },
        {
            title: CartonlabelRenderer("Status", 'CartonIDStatus'),
            dataIndex: "CartonIDStatus",
            key: "-1",
            width: 100
        },
        {
            title: CartonlabelRenderer("Booked", 'BookingTimeStamp'),
            dataIndex: "BookingTimeStamp",
            key: "3",
            width: 150
        },
        {
            title: CartonlabelRenderer("Picked Up", 'BookingTimeStamp'),
            dataIndex: "BookingTimeStamp",
            key: "4",
            width: 150,

        },
        {
            title: CartonlabelRenderer("Loaded", 'LoadTimeStamp'),
            dataIndex: "LoadTimeStamp",
            key: "5",
            width: 150
        },
        // {
        //     title: "Load Number",
        //     dataIndex: "LoadNumber",
        //     key: "10",
        //     width: 100

        // },
        {
            title: CartonlabelRenderer("Container Number", 'ContainerNumber'),
            dataIndex: "ContainerNumber",
            key: "9",
            width: 100
        },
        {
            title: CartonlabelRenderer("Seal Number", 'SealNumber'),
            dataIndex: "SealNumber",
            key: "8",
            width: 100

        },
        {
            title: 'Shipped',
            dataIndex: "ShippedTimeStamp",
            key: "6",
            width: 150
        },
        {
            title:CartonlabelRenderer("Delivered", 'DeliveredTimeStamp'),
            dataIndex: "DeliveredTimeStamp",
            key: "7",
            width: 150
        },
        {
            title: "PO Details",
            width: 100,
            // dataIndex: "PONumber",
            key: "0",
            fixed: "right",
            render: PODetailRenderer,
        },
        {
            title: "Notes",
            key: "operation",
            fixed: "right",
            width: 150,
            render: (data) => {
                console.log('note data', JSON.stringify(data))
                return (
                    <TableNote data={data} endPoint={`${API.CARTONS}/${data.id}.json?[carton][Note]=`} />
                )
            }
        },
    ];
    console.log('dataeeeee', value)
    return (
        <Modal
            open={true}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={styles.mainContainer} style={{ backgroundColor: 'transparent' }} onClick={() => { }}>
                <div style={{ backgroundColor: colors.backgroundColor, margin: 30, marginInline: 20, paddingBottom: 10, borderRadius: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div />
                        <div style={{ alignSelf: 'flex-end', marginRight: 50, marginTop: 50, }}>
                            <div onClick={closeModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div />
                                <CloseIcon style={{ color: colors.black }} />
                            </div>
                            <div className={styles.paginationContainer} style={{ marginBottom: '10px' }}>
                                {/* <input type="text" name="first_name" required placeholder="Search" value={search} onChange={(text) => setSearch(text.target.value)} /> */}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '95%', marginInline: '2.5%' }} onClick={() => { }}>
                        <div className={styles.paginationContainer} style={{ marginBottom: '10px' }}>
                            <div />
                            {/* <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                        <p style={{margin:'5px',marginRight:'10px'}}>Select date to search Timestamp </p>  
                        <DateTimePicker onChange={onChange} value={value} />
                          </div> */}
                        </div>
                        <Table
                            loading={!tableLoading}
                            pagination={{ defaultPageSize: 10, position: ['none'], showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                            onClick={(e) => {
                                console.log('Content: ', e.currentTarget.dataset.id);
                            }}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        console.log('i am in click')
                                    }, // click row
                                    onDoubleClick: (event) => { }, // double click row
                                    onContextMenu: (event) => { }, // right button click row
                                    onMouseEnter: (event) => { }, // mouse enter row
                                    onMouseLeave: (event) => { } // mouse leave row
                                };
                            }}
                            onHeaderRow={(columns, index) => {
                                return {
                                    onClick: () => { } // click header row
                                };
                            }}
                            // className={tableCSS}
                            columns={modelCoumns}
                            dataSource={tableData}
                            scroll={{
                                // x: 1000,
                                y: 500
                            }}
                        />
                        <div className={styles.paginationContainer}>
                            <div />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className={styles.exportButton} onClick={() => { }} style={{ backgroundColor: colors.primary }}>
                                    {/* <div onClick={()=>{
                                    window.('http://3.135.30.168:3000/cartons.csv')
                                }}><p style={{marginTop:5,marginBottom:5,color:colors.white,fontWeight:'bold'}}>Export as CSV</p></div> */}
                                    {/* <Link to="http://3.135.30.168:3000/cartons.csv" target="_blank" download>Download</Link> */}
                                    {/* <a href="http://3.135.30.168:3000/cartons.csv" download="cartonss.csv"> */}
                                    <a href={base_url+API.CARTONS_CSV} download="cartons_s.csv">
                                        <p style={{ marginTop: 5, marginBottom: 5, color: colors.white, fontWeight: 'bold' }}>Export as CSV</p>
                                    </a>
                                </div>
                                <Pagination showSizeChanger={false} defaultCurrent={1} total={total} onChange={(page) => setCurrentPage(page)} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Cartons
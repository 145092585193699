import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { colors } from '../../util/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
function DetailedBarChart(props) {
    const { state } = useLocation()
    const navigate = useNavigate()
    const { title, text, barsColor, data } = state
    console.log('params in detailedChartBar', data)
    const options = {
        responsive: true,

        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        max: 3,
                        min: 0,
                        stepSize: 1
                    }
                }]
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
            label: {
                display: false
            }
        },
    };

    // const labels = ['', '', '', '', '', '', ''];
    let labels = data?.map(item => item.Day)
    let maxLength = labels.includes(31) ? 31 : 30
    labels = []
    for (let i = 1; i <= 30; i++) {
        if (labels[i] != i) {

            labels.push(i)
        }
    }
    labels.sort(function (a, b) { return a - b })
    let map = {}
    for (let i = 0; i < data.length; i++) {
        if (!map[data[i].Day]) {
            map[data[i].Day] = data[i].total
        }
    }

    const barData = {
        labels: labels.length != 0 ? labels : ['1'],
        datasets: [
            {
                label: 'Booked',
                data: labels.map(item => !map[item] ? 0 : map[item]),
                backgroundColor: barsColor,
            },
        ],
    };
    return (
        <div style={mainContainer}>
            <div onClick={() => { navigate(-1) }} style={{ position: 'absolute', top: 40, left: 40,cursor:'pointer',padding:10}}>
                <ArrowBackIcon style={{ color: colors.black }} />
            </div>
            <div style={{ marginInlineEnd: '5px' }}>
                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '180%', marginTop: '5px' }}>{title}</p>
                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '130%', }}>{text}</p>
            </div>
            <div style={{ width: '80%', display: 'flex', alignItems: 'center' }}>
                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '130%', marginInlineEnd: '10px' }}>{'Number of Cartons'}</p>
                <Bar options={options} data={barData} />
            </div>
            <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '130%', }}>{'Days'}</p>

        </div>
    )
}
const mainContainer = {
    borderRadius: '10px',
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    // flex:1,
    // marginInlineStart: '20px',
    paddingInline: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '30px'
}
export default DetailedBarChart
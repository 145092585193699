import React from 'react'
import { colors } from '../util/colors';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
function AscendDescend({ label, setAscend, ascend, tableType, value }) {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{label}</div>
            <div style={{ cursor: 'pointer' }} onClick={() => {
                if (ascend?.type == label) {
                    setAscend({ type: label, value: ascend.value == 'asc' ? 'desc' : 'asc', tableType, columnName: value })
                } else
                    setAscend({ type: label, value: 'asc', tableType, columnName: value })
            }}>
                {ascend?.type == label ? ascend.value == 'asc' ?
                    <KeyboardArrowDownIcon sx={{ fontSize: 20 }} style={{ color: colors.blue }} /> :
                    <KeyboardArrowUpIcon sx={{ fontSize: 20 }} style={{ color: colors.blue }} /> :
                    <KeyboardArrowRightIcon sx={{ fontSize: 20 }} style={{ color: colors.blue }} />
                }
            </div>
        </div>
    )
}

export default AscendDescend
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import '../App.css'
import BackgroundImage from '../assets/images/bg.png'
import { colors } from '../util/colors'

export default function LandingPage() {
    useEffect(() => {
        document.title = 'KWITbox'
    }, [])
    return (
        <div style={{ backgroundColor: 'white' }}>
            <header style={HeaderStyle}>
            </header>
            {/* <h1 className="main-title text-center" style={{ color: colors.black }}>KWITbox </h1>
                <p className="main-para text-center" style={{ color: colors.black }}>join us now and don't waste time</p> */}
            <div className="buttons text-center">
                <Link to="/login">
                    <button className="primary-button" style={{ backgroundColor: colors.primary, width: '500px', borderRadius: '20px' }}>log in</button>
                </Link>
                {/* <Link to="/register">
                    <button className="primary-button" id="reg_btn"><span>register </span></button>
                </Link> */}
            </div>
        </div>
    )
}

const HeaderStyle = {
    width: "100%",
    height: "50vh",
    // background:'red',
    background: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
}
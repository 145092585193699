import moment from "moment";
export function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export function dateConvertor(date) {
  return moment(date).format('M/DD/YYYY')
}
export function timeFormater(time) {
  return moment(time).format('HH:mm')
}
export function dateFormater(time) {
  return moment(time).utc().format('DD-MM-YYYY')
}
export function countFormatter(n, d) {
  let x = ('' + n).length
  let p = Math.pow
  d = p(10, d)
  x -= x % 3
  return Math.round(n * d / p(10, x)) / d + " kMGTPE"[x / 3]
}

export const colors = {
    backgroundColor: 'rgb(243, 243, 243)',
    primary: '#FF8C00',
    primaryDark: '#1F6521',
    secondary: '#D6CE15',
    secondaryLight: '#FBE8A6',
    black: '#1F2605',
    grey: 'grey',
    lightGrey: '#d3d3d3',
    red: 'red',
    white: 'white',
    transparent: 'transparent',
    blue: 'blue',
}
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import LandingPage from "./screens/LandingPage"
import AuthHomePage from "./screens/HomePage"
import LoginPage from "./screens/LoginPage"
import RegisterPage from "./screens/RegisterPage"
import ForgetPasswordPage from "./screens/ForgetPasswordPage"
import './App.css'
import { useEffect, useState } from 'react'
import Dashboard from './screens/home/Dashboard'
import DetailedBarChart from './screens/home/DetailedBarChart'
import Maps from './screens/home/Maps'
import { colors } from './util/colors'

export default function App() {
    const [localUser, setLocalUser] = useState('')
    const loginedUser = localStorage.getItem('LogedUser')
    useEffect(() => {
        setLocalUser(loginedUser)
    }, [loginedUser])
    // const userLocal=localStorage.getItem('typeOfUser')
    const protectedRoutes = (element, secure) => {
        return element

        // localStorage.removeItem('LogedUser')

        console.log('-------->router', localUser)
        if (!secure) {
            if (localUser) {
                return <Navigate to={'/dashboard'} />
            }
            return element
        } else if (secure && localUser) {
            return element
        } else {

        }
        return <Navigate to={'/'} />
        // return element
    }


    return (
        <div style={{ backgroundColor: colors.backgroundColor }}>
            <Routes>
                <Route exact path="/" element={protectedRoutes(<LandingPage />, false)} />
                <Route path="login" element={protectedRoutes(<LoginPage />, false)} />
                <Route path="register" element={protectedRoutes(<RegisterPage />, false)} />
                <Route path="forget-password" element={protectedRoutes(<ForgetPasswordPage />, false)} />
                <Route path="home" element={protectedRoutes(<AuthHomePage />, false)} />

                <Route path="dashboard" element={protectedRoutes(<Dashboard />, true)} />
                <Route path="maps" element={protectedRoutes(<Maps />, true)} />
                {/* <Route path="cartons" element={protectedRoutes(<Cartons />, true)} /> */}
                <Route path="barChart" element={protectedRoutes(<DetailedBarChart />, true)} />

                <Route path="*" element={protectedRoutes(<LandingPage />, false)} />
            </Routes>

        </div>

    )
}
const Footer = () => {
    return (
        <p className="text-center" style={FooterStyle}>Designed & coded by Rebellions</p>
    )
}

const FooterStyle = {
    background: "#222",
    fontSize: ".8rem",
    color: "#fff",
    position: "absolute",
    bottom: 0,
    padding: "1rem",
    margin: 0,
    width: "100%",
    opacity: ".5"
}

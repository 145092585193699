import React, { useState } from 'react'
import { colors } from '../util/colors'
import { API, requestPut } from '../util/request'

function TableNote({data,endPoint}) {
    const [note, setNote] = useState(data?.Note)
    return (
        <div>
            <input style={{
                width: '100%',
                backgroundColor: colors.lightGrey
            }}
                placeholder='Notes' value={note}
                onChange={(e) =>{
                     setNote(e.target.value)
                     requestPut(`${endPoint}${e.target.value}`)
                    }}
                // onBlur={() => {
                //     requestPut(`${endPoint}${note}`)
                // }}
            />
        </div>
    )
}

export default TableNote
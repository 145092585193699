import React from 'react'
import { colors } from '../util/colors'
import { countFormatter } from '../util/common'
import LoadingSpinner from './LoadingSpinner'
function HomeCountCard({ image, text, count,onCardPress}) {

    return (
        <div style={mainContainer}  onClick={onCardPress}>
            {count == -1 ? <div>
                <LoadingSpinner />
            </div> :
                <>
                    {/* <p style={{ fontWeight: 'bold', marginInline: '15px', fontSize: '18px',}}>{text}</p> */}
                    <img src={image} style={{ width: '90px', resize: 'contain' }} />
                    <p style={{ fontWeight: 'bold', marginInline: '15px', fontSize: '18px', marginTop: '10px',marginBottom:0 }}>{countFormatter(count, 1)}</p>
                </>
            }
        </div>
    )
}
const mainContainer = {
    display:'flex',
    flexDirection:'column',
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: '15px',
    cursor: 'pointer',
}
export default HomeCountCard
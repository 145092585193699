import { Pagination, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import styles from '../css/Cartons.module.css'
import { API, base_url, fetchrequestGet, requestGet, requestPut } from '../util/request';
import { colors } from '../util/colors';
import TableNote from './TableNote';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomInput from './CustomInput';
import DateTimePicker from 'react-datetime-picker';
import CustomDateTimePicker from './CustomDateTimePicker';
import CustomButton from './CustomButton';

function Filter({ closeModal }) {
    var date = new Date();
    const [currentPage, setCurrentPage] = useState(1)
    const [minPO, setMinPO] = useState('')
    const [maxPO, setMaxPO] = useState('')
    const [POError, setPOError] = useState(false)
    const [minSKU, setMinSKU] = useState('')
    const [maxSKU, setMaxSKU] = useState('')
    const [SKUError, setSKUError] = useState(false)
    const [minQty, setMinQty] = useState('')
    const [maxQty, setMaxQty] = useState('')
    const [qtyError, setQtyError] = useState(false)
    const [minShipped, setMinShipped] = useState('')
    const [maxShipped, setMaxShipped] = useState('')
    const [shippedError, setShippedError] = useState(false)
    const [minCanceled, setMinCanceled] = useState('')
    const [maxCanceled, setMaxCanceled] = useState('')
    const [canceledError, setCanceledError] = useState(false)
    const [search, setSearch] = useState('')
    const [minShippedDate, setMinShippedDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [maxShippedDate, setMaxShippedDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    const [minCanceledDate, setMinCanceledDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [maxCanceledDate, setMaxCanceledDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    // console.log('date------->',minShippedDate,maxShippedDate)
    useEffect(() => {
      if(minQty<maxQty){
        setQtyError(true)
      }
    }, [currentPage, search])

    return (
        <Modal
            open={true}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={styles.mainContainer} style={{ backgroundColor: 'transparent', display: 'flex',overflow: 'scroll' }} onClick={() => { }}>
                <div style={{ backgroundColor: colors.backgroundColor, paddingInline: 70, paddingBottom: 10, paddingTop: 10, borderRadius: 10, marginInline: 20, }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                        <div style={filterContainer}>Filters</div>
                        <div onClick={closeModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                            <div />
                            <CloseIcon style={{ color: colors.black }} />
                        </div>
                    </div>
                    <div style={{ border: '1px solid #ddd', borderRadius: 30, padding: 20, marginBottom: 20 }}>
                        <div style={{}}>
                            <div style={itemContainer}>PO Range</div>
                            <div>
                                <CustomInput placeholder={'Min PO'} value={minPO} setValue={(text) => {
                                    setMinPO(text)
                                    setPOError(false)
                                }} error={POError} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                <CustomInput placeholder={'Max PO'} value={maxPO} setValue={(text) => {
                                    setPOError(false)
                                    setMaxPO(text)
                                }} error={POError} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                            </div>
                        </div>
                        <div style={{}}>
                            <div style={itemContainer}>SKU Range</div>
                            <div>
                                <CustomInput placeholder={'Min SKU'} value={minSKU} setValue={(text) => {
                                    setMinSKU(text)
                                    setSKUError(false)
                                }} error={SKUError} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                <CustomInput placeholder={'Max SKU'} value={maxSKU} setValue={(text) => {
                                    setMaxSKU(text)
                                    setSKUError(false)
                                }} error={SKUError} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                            </div>
                        </div>
                        <div style={{}}>
                            <div style={itemContainer}>Quantity Range</div>
                            <div>
                                <CustomInput placeholder={'Min Qty'} value={minQty} setValue={(text) => {
                                    setMinQty(text)
                                    setQtyError(false)
                                }} error={qtyError} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                <CustomInput placeholder={'Max Qty'} value={maxQty} setValue={(text) => {
                                    setMaxQty(text)
                                    setQtyError(false)
                                }} error={qtyError} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                            </div>
                        </div>
                        {/* <div style={{}}>
                            <div style={itemContainer}>Shipped Date Range</div>
                            <div>
                                <CustomDateTimePicker value={minShippedDate} setValue={setMinShippedDate} error={shippedError}/>
                                <CustomDateTimePicker value={maxShippedDate} setValue={setMaxShippedDate} error={shippedError}/>
                            </div>
                        </div> */}
                        {/* <div style={{}}>
                            <div style={itemContainer}>Canceled Date Range</div>
                            <div>
                                <CustomDateTimePicker value={minCanceledDate} setValue={setMinCanceledDate} error={canceledError}/>
                                <CustomDateTimePicker value={maxCanceledDate} setValue={setMaxCanceledDate} error={canceledError}/>
                            </div>
                        </div> */}

                        {/* <div style={{}}>
                            <div style={itemContainer}>Color Description</div>
                            <div>
                                <CustomInput placeholder={'Color'} value={minQty} setValue={(text) => setMinQty(text)} />
                            </div>
                        </div> */}
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div/>
                        <CustomButton title={'Search'} onPress={()=>console.log('asdf')}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
const filterContainer = { fontSize: 23, fontWeight: 'bold', marginTop: 30, marginBottom: 30, color: colors.primary }
const itemContainer = { fontSize: 19, fontWeight: 'bold', marginBottom: 10, marginTop: 10 }
export default Filter
import axios from 'axios'
// import AsyncStorage from '@react-native-async-storage/async-storage';

export const base_url = "https://api.knowwhatsinthebox.com/";
const image_url = ""
export const API = {
    IMAGE_URL: image_url,
    REGISTER: 'auth',
    LOGIN: 'auth/sign_in ',
    PO_SEARCH: 'po_search.json',
    PO_LINES: 'po_lines',
    PO_Line_Detail: 'po_line_details.json',
    PO_Line_Detail_CSV: 'po_line_details.csv',
    CARTONS: 'cartons.json',
    CARTONS_CSV: 'cartons.csv',
    BOOKED_COUNT: 'bookings/count',
    PICKED_UP_COUNT: 'pick_ups/count',
    LOADINGS_COUNT: 'loadings/count',
    SHIPPED_COUNT: 'shippeds/count',
    DELIVERED_COUNT: 'delivereds/count',
    PO_LINES_COUNT: 'po_lines/count',
    COUNT_BY_DAY: 'bookings/count_by_day',

}

export const fetchrequestGet = (url, extraHeaders = {}) => {
    return new Promise((resolve, reject) => {
        console.log('urrl',base_url+url)
        fetch(base_url+url).then(response => response.json())
            .then(response => {
                console.log('API', 'fetchrequestGet-response.status', response);
                resolve(response);
            }).catch(error => {
                console.log('API', 'requestGet-error', error);
                reject(error);
            });
    });
}
export const requestGet = (url, extraHeaders = {}, data) => {
    return new Promise((resolve, reject) => {
        axios.get(base_url+url, {
            // params:'where%5Bq%5D[PONumber_cont]=7&where%5Bq%5D[LNSKUNumber_cont]=7',
            headers: {
                "Accept": "application/json",
                "content-type": "application/json",
                ...extraHeaders
            },

        }).then(response => {
            console.log('API', 'requestGet-response.status', response.data);
            resolve(response.data);
        }).catch(error => {
            console.log('API', 'requestGet-error', error);
            reject(error);
        });
    });
}
export const requestPut = (url, data, extraHeaders = {}) => {
    const formData = new FormData();
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            formData.append(key, data[key])
        }
    }
    console.log('formData--->', formData);
    // for react only
    for (var key of formData.entries()) {
        console.log('formData--->', key[0] + ', ' + key[1]);
    }
    return new Promise((resolve, reject) => {
        axios.put(base_url + url, formData, {
            headers: {
                "Accept": "application/json",
                ...extraHeaders
            }
        }).then(response => {
            console.log('API', base_url + url, 'requestPost-response.status', response.data);
            resolve(response.data);
        }).catch(error => {
            console.log('API', 'requestPost-error', error);
            reject(error);
        });
    });
}
export const requestPost = (url, data, extraHeaders = {}) => {
    const formData = new FormData();
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            // console.log('on keysss',key,data[key])
            formData.append(key, data[key])
        }
    }
    console.log('formData--->', formData);
    // for react only
    for (var key of formData.entries()) {
        console.log('formData--->', key[0] + ', ' + key[1]);
    }
    return new Promise((resolve, reject) => {
        axios.post(base_url + url, formData, {
            headers: {
                "Accept": "application/json",
                ...extraHeaders
            }
        }).then(response => {
            console.log('API', base_url + url, 'requestPost-response.status', response.data);
            resolve(response.data);
        }).catch(error => {
            console.log('API', 'requestPost-error', error);
            reject(error);
        });
    });
}
axios.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('token')
        const requestConfig = config;
        requestConfig.headers = {
            Authorization: `Bearer ${token}`,
            "Accept": "application/json",
        };
        console.log('RESQUEST', config);
        return requestConfig;
    },
    err => {
        console.log('Error', err);
        return Promise.reject(err);
    },
);
import React from 'react'
import { colors } from '../util/colors'

function CustomButton({ title,onPress }) {
    return (
        <div onClick={() => {onPress() }} style={exportButton}>
            <p style={{ marginTop: 5, marginBottom: 5, color: colors.white, fontWeight: 'bold' }}>{title}</p>
        </div>
    )
}
const exportButton = {
    display: 'flex',
    paddingInline: '15px',
    paddingBottom: '0px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginRight: '10px',
    backgroundColor: colors.primary,
    cursor:'pointer',
}

export default CustomButton
import { useState } from "react";
import Draggable from "react-draggable";
import { colors } from "../util/colors";
import CloseIcon from '@mui/icons-material/Close';

function IFrame({ show, setShow }) {
    // const [zIndex,setZIndex]=useState(1)
    // setTimeout(() => {
    //     setZIndex(9)
    // }, 200);
    return (
        <Draggable
            // axis="x"
            // handle=".handle"
            defaultPosition={{ x: 0, y: 0 }}
            position={null}
            // grid={[25, 25]}
            scale={1}
        // onStart={this.handleStart}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
        >
            <div style={{ position: 'absolute', zIndex: show ? 5 : -1, right: '15%', display: show ? '' : 'none' }}>
                <div style={{ width: 600, height: 800, backgroundColor: colors.grey, padding: '5%', paddingTop: 0, cursor: 'grab', borderRadius: 10, marginTop: 50, }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <div style={{ cursor: 'pointer', }} onClick={setShow}><CloseIcon style={{ color: colors.white }} /></div>
                    </div>
                    <iframe src="https://app.knowwhatsinthebox.com/Login.aspx" allow="geolocation" width={'100%'} height={'98%'} style={{ borderRadius: 10 }}></iframe>

                </div>
            </div>
        </Draggable>
    );
}

export default IFrame;
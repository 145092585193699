import { Pagination, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import styles from '../css/Cartons.module.css'
import { API, base_url, fetchrequestGet, requestGet, requestPut } from '../util/request';
import { colors } from '../util/colors';
import TableNote from './TableNote';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AscendDescend from './AscendDescend';
function PODetails({ closeModal, cartonsEndPoint }) {

    const [tableData, setTableData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState('')
    const [tableLoading, SetTableLoading] = useState(false)
    const [value, onChange] = useState(new Date());
    const [ascend, setAscend] = useState({})

    useEffect(() => {
        getTableData()
    }, [currentPage, search, ascend])
    const getTableData = async () => {
        SetTableLoading(false)
        var url = cartonsEndPoint
        if (ascend.columnName) {
            url += `&[q][s]=${ascend.columnName} ${ascend.value}`
        }
        if (search)
            var message = await requestGet(`${cartonsEndPoint}?[q][]=${search}`)
        else
            var message = await requestGet(url)

        console.log('---------->', message)
        setTotal(message?.pagination?.count ?? 1)

        setTableData(message?.po_line_details ?? [])
        SetTableLoading(true)
    }
    console.log('dataeeeee', value)
    const PODetailslabelRenderer = (label, value) => {
        return <AscendDescend label={label} setAscend={(data) => { setAscend(data) }} ascend={ascend} tableType={'PO_Details'} value={value} />
    }
    const modelCoumns = [
        {
            title: PODetailslabelRenderer("UPC Code", 'UPCCode'),
            width: 120,
            dataIndex: "UPCCode",
            key: "-2",
            fixed: "left"
        },
        {
            title: PODetailslabelRenderer("PO", 'PONumber'),
            width: 90,
            dataIndex: "PONumber",
            key: "0",
            fixed: "left"
        },
        {
            title: PODetailslabelRenderer("SKU", 'LNSKUNumber'),
            dataIndex: "LNSKUNumber",
            key: "1",
            width: 90
        },
        {
            title: PODetailslabelRenderer("SKU Desc", 'SKUDescription'),
            dataIndex: "SKUDescription",
            key: "-1",
            width: 100
        },
        {
            title: PODetailslabelRenderer("Style Number", 'StyleNumber'),
            dataIndex: "StyleNumber",
            key: "3",
            width: 150
        },
        {
            title: PODetailslabelRenderer("Style Desc", 'StyleDescription'),
            dataIndex: "StyleDescription",
            key: "4",
            width: 150,

        },
        {
            title: PODetailslabelRenderer("Size Desc", 'SizeDescription'),
            dataIndex: "SizeDescription",
            key: "5",
            width: 150
        },
        {
            title: PODetailslabelRenderer("Color Desc", 'ColorDescription'),
            dataIndex: "ColorDescription",
            key: "10",
            width: 100

        },
        {
            title: PODetailslabelRenderer("Quantity Per Carton", 'QuantityPerCarton'),
            dataIndex: "QuantityPerCarton",
            key: "10",
            width: 100

        },

    ];
    return (
        <Modal
            open={true}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={styles.mainContainer} style={{ backgroundColor: 'transparent' }} onClick={() => { }}>
                <div style={{ backgroundColor: colors.backgroundColor, margin: 30, marginInline: 20, paddingBottom: 10, paddingTop: 10, borderRadius: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignItems: 'center', }}>
                        <div style={{ fontSize: 23, marginLeft: 50, fontWeight: 'bold' }}>PO Details</div>
                        <div style={{ alignSelf: 'flex-end', marginRight: 50, }}>
                            <div onClick={closeModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                                <div />
                                <CloseIcon style={{ color: colors.black }} />
                            </div>
                            {/* <div className={styles.paginationContainer} style={{ marginBottom: '10px' }}>
                            <input type="text" name="first_name" required placeholder="Search" value={search} onChange={(text) => setSearch(text.target.value)} />
                        </div> */}
                        </div>
                    </div>
                    <div style={{ width: '95%', marginInline: '2.5%' }} onClick={() => { }}>
                        <div className={styles.paginationContainer} style={{ marginBottom: '10px' }}>
                            <div />
                        </div>
                        <Table
                            loading={!tableLoading}
                            pagination={{ defaultPageSize: 10, position: ['none'], showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                            onClick={(e) => {
                                console.log('Content: ', e.currentTarget.dataset.id);
                            }}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        console.log('i am in click')
                                    }, // click row
                                    onDoubleClick: (event) => { }, // double click row
                                    onContextMenu: (event) => { }, // right button click row
                                    onMouseEnter: (event) => { }, // mouse enter row
                                    onMouseLeave: (event) => { } // mouse leave row
                                };
                            }}
                            onHeaderRow={(columns, index) => {
                                return {
                                    onClick: () => { } // click header row
                                };
                            }}
                            // className={tableCSS}
                            columns={modelCoumns}
                            dataSource={tableData}
                            scroll={{
                                // x: 1000,
                                y: 500
                            }}
                        />
                        <div className={styles.paginationContainer}>
                            <div />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className={styles.exportButton} onClick={() => { }} style={{ backgroundColor: colors.primary }}>
                                    {/* <div onClick={()=>{
                                    window.('http://3.135.30.168:3000/cartons.csv')
                                }}><p style={{marginTop:5,marginBottom:5,color:colors.white,fontWeight:'bold'}}>Export as CSV</p></div> */}
                                    {/* <Link to="http://3.135.30.168:3000/cartons.csv" target="_blank" download>Download</Link> */}
                                    <a href={base_url+API.PO_Line_Detail_CSV} download="PO_Detail_s.csv">
                                        <p style={{ marginTop: 5, marginBottom: 5, color: colors.white, fontWeight: 'bold' }}>Export as CSV</p>
                                    </a>
                                </div>
                                <Pagination showSizeChanger={false} defaultCurrent={1} total={total} onChange={(page) => setCurrentPage(page)} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PODetails
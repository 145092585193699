import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { colors } from '../util/colors';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
function MiniBarChart({ title, text, barsColor, data }) {
    const navigate = useNavigate()
    const options = {
        responsive: true,

        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        max: 3,
                        min: 0,
                        stepSize: 1
                    }
                }]
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
            label: {
                display: false
            }
        },
    };

    // const labels = ['', '', '', '', '', '', ''];
    const labels = data?.map(item => item.Day)


    const barData = {
        labels: labels.length != 0 ? labels : ['1'],
        datasets: [
            {
                label: 'Booked',
                data: data?.map(item => item.total),
                backgroundColor: barsColor,
            },
        ],
    };
    return (
        <div style={mainContainer} onClick={() => {
            if (data != -1)
                navigate('/barChart', { state: { title, text, barsColor, data } })
        }}>
            {data == -1 ? <div style={{ margin: '12px', paddingInline: '70px' }}>
                <LoadingSpinner />
            </div>
                : <><div style={{ marginInlineEnd: '5px', }}>
                    <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '110%', marginTop: '5px' }}>{title}</p>
                    <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{text}</p>
                </div>
                    <div style={{ width: '150px', height: '60px', }}>
                        <Bar options={options} data={barData} />;
                    </div></>
            }
        </div>
    )
}
const mainContainer = {
    borderRadius: '10px',
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'row',
    marginInlineStart: '20px',
    paddingInline: '10px',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden'
}
export default MiniBarChart
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import '../../App.css'
import { colors } from '../util/colors'
import { isValidEmail } from '../util/common'
import { API, requestPost } from '../util/request'

export default function SignUpPage() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [confirmPassword,setConfirmPassword]=useState('')
    const [userName,setUserName]=useState('')
    useEffect(() => {
        if (errorMessage) {
            setErrorMessage('')
        }
    }, [email, password])

    const onSubmit = async (event) => {
        event.preventDefault();
        if(!userName||!email||!password||!confirmPassword){
            setErrorMessage('All fields are required')
            return;
        }
        if (password.length < 3) {
            setErrorMessage('Invalid Password')
            return;
        }
        if(password!==confirmPassword){
            setErrorMessage('Confrim password do not match')
            return;
        }
        if (!isValidEmail(email)) {
            setErrorMessage('Invalid Email')
            return;
        }
        let data = {
            user_name:userName,
            email,
            password,
            password_confirmation:confirmPassword,
        }
        const response = await requestPost(API.REGISTER, data)
        console.log('api============>', response)
        if (response.code == 200) {
            localStorage.setItem('LogedUser', JSON.stringify(response.data.user))
            console.log('setting data', response.data.user)
            localStorage.setItem('token', response.data.token)

            window.location.reload()
        } else {
            setErrorMessage(response.message ? response.message : 'Something went wrong!')
        }
    }
    return (
        <div className="text-center m-5-auto">
            <h2>Join us</h2>
            <h5>Create your personal account</h5>
            <form>
                <p>
                    <label>Username</label><br />
                    <input type="text" name="first_name" required value={userName} onChange={(text) => setUserName(text.target.value)} />
                </p>
                <p>
                    <label>Email address</label><br />
                    <input type="email" name="email" required value={email} onChange={(text) => setEmail(text.target.value)} />
                </p>
                <p>
                    <label>Password</label><br />
                    <input type="password" name="password" required value={password} onChange={(text) => setPassword(text.target.value)} />
                </p>
                <p>
                    <label>Confirm Password</label><br />
                    <input type="password" name="password" required value={confirmPassword} onChange={(text) => setConfirmPassword(text.target.value)} />
                </p>
                {/* <p>
                    <input type="checkbox" name="checkbox" id="checkbox" required /> <span>I agree all statements in <a href="https://google.com" target="_blank" rel="noopener noreferrer">terms of service</a></span>.
                </p> */}
                {errorMessage != '' && <p><label style={{ color: colors.red, marginBottom: '5px' }}>{errorMessage}</label></p>}

                <p>
                    <button id="sub_btn" type="submit" onClick={onSubmit}>Register</button>
                </p>
            </form>
            <footer>
                <p><Link to="/">Back to Homepage</Link>.</p>
            </footer>
        </div>
    )

}

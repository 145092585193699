import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import '../../App.css'
import { colors } from '../util/colors'
import { isValidEmail } from '../util/common'
import { API, requestPost } from '../util/request'

export default function SignInPage() {
    // const [createAccountAlert, setCreateAccountAlert] = useState(false)
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [user, setUser] = useState(false)
    useEffect(() => {
        document.title = 'Login'
        if (errorMessage) {
            setErrorMessage('')
        }
    }, [email, password])

    const onSubmit = async (event) => {
        event.preventDefault();
        if (!isValidEmail(email)) {
            setErrorMessage('Invalid Email')
            return;
        }
        if (password.length < 3) {
            setErrorMessage('Invalid Password')
            return;
        }
        let data = {
            email,
            password
        }
        try {
            const response = await requestPost(API.LOGIN, data)
            console.log('api============>', response)
            localStorage.setItem('LogedUser', JSON.stringify(response?.data))
            // localStorage.setItem('token', response.data.token)
            if (response?.data)
                navigate('/dashboard')
            setUser(true)
            // window.location.reload()
        }
        catch (error) {
            setErrorMessage('Something went wrong!')
            console.log('error in login', error)
        }
        // if (response.code == 200) {
        //     localStorage.setItem('LogedUser', JSON.stringify(response.data.user))
        //     console.log('setting data', response.data.user)
        //     localStorage.setItem('token', response.data.token)

        //     window.location.reload()
        // } else {
        //     setErrorMessage(response.message ? response.message : 'Something went wrong!')
        // }
    }
    // if (user)
    //     navigate('/dashboard')
    return (
        <div className="text-center m-5-auto" style={{backgroundColor:colors.white}}>
            <h2>Sign in</h2>
            <form >
                <p>
                    <label>Email address</label><br />
                    <input type="text" name="first_name" required value={email} onChange={(text) => setEmail(text.target.value)} />
                </p>
                <p>
                    <label>Password</label>
                    {/* <Link to="/forget-password"><label className="right-label">Forget password?</label></Link> */}
                    <br />
                    <input type="password" name="password" required value={password} onChange={(text) => setPassword(text.target.value)} />
                </p>

                {errorMessage != '' && <p><label style={{ color: colors.red, marginBottom: '5px' }}>{errorMessage}</label></p>}
                <p>
                    <button style={{backgroundColor:colors.primary}} id="sub_btn" type="submit" onClick={onSubmit}>Login</button>
                </p>
            </form>
            <footer>
                {/* <p>First time? <Link to="/register">Create an account</Link>.</p>
                <p><Link to="/">Back to Homepage</Link>.</p> */}
            </footer>
            {/* <Modal
                title="Modal 1000px width"
                // This was removed
                // centered
                visible={true}
            // onOk={() => setVisible(false)}
            // onCancel={() => setVisible(false)}
            // This was removed
            // width={'1000'}
            >
                <p>some contents...</p>
                <p>some contents...</p>
                <p>some contents...</p>
            </Modal> */}
        </div>
    )
}
